import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RTL from "components/rtl";
import { routes } from "./routes";
import { createCustomTheme } from "./theme";
import "./i18n";
import { AuthProvider } from "contexts/auth0Context";
import TelegramProvider, { useTelegram } from "contexts/TelegramContext";
import { Toaster } from "react-hot-toast";
import { connector } from "./connector";
import { useEffect } from "react";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import useSettings from "./hooks/useSettings";
import useReadInitData from "./hooks/useReadInitData";
import ReactGA from "react-ga4";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WalletProvider } from './provider/SolanaProvider'
import AlertTemplate from "./layouts/layout-parts/AlertTemplate";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'

// if (process.env.REACT_APP_IS_LOCAL !== "true") {
ReactGA.initialize("G-NNN70JEZPD" || "DUMMY");
// }

export default function App() {
  const { settings } = useSettings();
  // const { register } = useReadInitData()

  const theme = createCustomTheme(settings);
  const router = createBrowserRouter(routes());
  useEffect(() => {
    connector.restoreConnection();
  }, []);
  const options = {
    position: positions.TOP_LEFT,
    transition: transitions.FADE
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <AlertProvider template={AlertTemplate}  {...options}>
          <AuthProvider>
            <WalletProvider>
              <RTL>
                <CssBaseline />
                <RouterProvider router={router} />
                <Toaster />
              </RTL>
            </WalletProvider>
          </AuthProvider>
        </AlertProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
