import { useEffect, useState } from 'react';
const useLocalStorage = (key, initialValue) => {
  const [data, setData] = useState(initialValue);
  useEffect(() => {
    const getData = window.localStorage.getItem(key);
    console.log("getDataStorge",getData)
    if (getData) {
      const settings = JSON.parse(getData);
      console.log("getDataStorge",settings)
      if(key==='settings' && settings.token===''){
        setData({...settings,token:'needToLogin'});
      } else {
        setData(settings);
      }
    } else if(key==='settings' && !getData){
      setData({...initialValue,token:'needToLogin'});
    }
  }, [key]);

  const storeData = updateValue => {
    setData(updateValue);
    window.localStorage.setItem(key, JSON.stringify(updateValue));
  };

  return {
    data,
    storeData
  };
};
export default useLocalStorage;