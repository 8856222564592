import { Backdrop, Box, Button, Card, Container, Grid, Stack, styled, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { NumberInput } from "../../components/NumberInput";
import { useCallback } from 'react';

import * as anchor from '@coral-xyz/anchor';

import { useConnection, useWallet, useAnchorWallet } from '@solana/wallet-adapter-react';

import { LoadingButton } from "@mui/lab";
import { findMetadataPda, findMasterEditionPda } from '@metaplex-foundation/mpl-token-metadata';

import IDL from '../../program/vault.json';
import { AnchorProvider } from '@coral-xyz/anchor';
import { hippoMint } from '../../constants/token';
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress, getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import Decimal from "decimal.js";
import { convertTxToURL } from "../../utils/convertTxToURL";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { BN } from "bn.js";
import {
    createSignerFromKeypair,
    signerIdentity,
    publicKey as umiPubkey,
} from "@metaplex-foundation/umi";
import { endpoint } from "utils/constants";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
const x = '981kdjjl0192flcjs'

export default function Admin() {
    const [p, setP] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()
    const { connected: isSolanaConnected } = useWallet()

    const [userInfo, setUserInfo] = useState()

    const wallet = useAnchorWallet()
    const provider = new AnchorProvider(connection, wallet, {})
    const program = new anchor.Program(IDL, provider)

    return <PageContainer >
        <Grid container minHeight={'100vh'}>
            <Grid item xs={12} sx={{ position: "relative" }}>
                {
                    accessToken === x ? <Container style={{ padding: '1rem 0px' }}>

                    </Container> : <Backdrop open={true} in={true} sx={{ position: 'absolute', background: 'none' }}>
                        <Stack spacing={2}>
                            <TextField
                                value={p}
                                onChange={e => setP(e.target.value)}
                                placeholder="Password"
                                sx={{
                                    border: 'none',
                                    '& .MuiInputBase-input': {
                                        color: '#000',
                                    }
                                }} />
                            <Button onClick={() => setAccessToken(p)} fullWidth sx={{ fontSize: '1.2rem' }}>Login</Button>
                        </Stack>
                    </Backdrop>
                }
            </Grid>
        </Grid>
    </PageContainer>
}

const PageContainer = styled(Stack)(() => ({
    minHeight: '100vh',
    background: '#F8FAFD',
    backgroundSize: "cover",
    position: 'relative',
    color: '#000'
}));