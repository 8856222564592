import { ClickAwayListener, Stack, Typography } from '@mui/material'
import React from 'react'

const AlertTemplate = ({ message, options, style, close }) => {

    return (
        <Stack justifyContent={'center'} alignItems={'center'} sx={{ width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.4)' }}>
            <ClickAwayListener onClickAway={close}>
                <Stack minWidth={{ xs: 300, md: 400 }} p={{ xs: 2, md: 3 }} minHeight={100} sx={{ color: '#60312E', position: 'relative', }}>
                    <Stack sx={{ position: "relative", zIndex: 10 }}>
                        {message}
                    </Stack>
                </Stack>
            </ClickAwayListener>
        </Stack>

    )
}

export default AlertTemplate