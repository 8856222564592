import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey } from '@solana/web3.js'

export interface Token {
  address: string
  decimals: number
  programId: string
  publicKey: PublicKey
  symbol: string
}

export interface TokenBalance {
  token: Token
  balance: number
}


export const hippoMint: Token = {
  symbol: 'HERO',
  address: 'Biem8k7vbzvCkHzGP2UzdhspLBbUuxZKHDvg9Uoupump',
  decimals: 6,
  programId: TOKEN_PROGRAM_ID.toBase58(),
  publicKey: new PublicKey('Biem8k7vbzvCkHzGP2UzdhspLBbUuxZKHDvg9Uoupump'),
}
