import React, { StrictMode } from "react";

// ROOT APP COMPONENT
import App from "./App";

// SITE SETTINGS CONTEXT
import SettingsProvider from "contexts/settingsContext";
import { render } from "react-dom";

// ALL THIRD PARTY LIBRARIES CSS
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "nprogress/nprogress.css";
import "react-quill/dist/quill.snow.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";


// Polyfill Buffer globally
import eruda from "eruda";

// eruda.init();

render(

  <StrictMode>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </StrictMode>,

  document.getElementById('root')
)

// // https://github.com/atlassian/react-beautiful-dnd/issues/2407
// root.render(
//  
//     <App />
//   </SettingsProvider>
// );
